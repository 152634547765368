import React from 'react';

import './swiper-bundle.min.css';
import './Testimonial.css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules'; // Correct import for Swiper modules in v9+

import { assets } from '../../assets/assets'; // Assuming this imports the images

// Example Testimonial data (can be expanded to include different names, jobs, etc.)
const testimonials = [
  {
    image: assets.adali,
    text: 'Khalid is a highly talented individual, brimming with knowledge and creativity. His work consistently exceeds expectations; when you request A, he delivers A+. He is friendly, continuously eager to learn, and passionate about exploring new concepts.',
    name: 'Dr. Mohd Adili',
    job: 'Supervisor',
  },
  {
    image: assets.yung,
    text: 'I had the pleasure of studying alongside Khalid Ali at Universiti Teknikal Malaysia Melaka. Khalid is not only a very kind and supportive friend but also an incredibly talented individual. His dedication to his studies and his willingness to help others are truly admirable. I am grateful to have met him and highly recommend him for his exceptional skills and positive attitude.',
    name: 'Yung Huey Tong ',
    job: 'Software Engineer',
  },
  {
    image: assets.andoli,
    text: 'Khalid Ali is an exceptionally talented software developer with a strong foundation in mobile app development. During his time as a student at UTeM, he consistently demonstrated a passion for creating innovative solutions and excelled in his coursework. Khalids ability to quickly grasp new concepts, combined with his collaborative spirit, makes him a valuable asset to any software development team. His dedication and skills are truly commendable.',
    name: 'Mohammed Al-Andoli',
    job: 'Senior Lecturer ',
  },
];

const Testimonial = () => {
  return (
    <section className="container">
      <Swiper
        className="testimonial"
        modules={[Navigation, Pagination]} // Add modules here
        navigation
        pagination={{ clickable: true }}
        loop={true}
      >
        {testimonials.map((testimonial, index) => (
          <SwiperSlide key={index} className="slide">
            <img
              src={testimonial.image}
              alt={`Testimonial by ${testimonial.name}`}
              className="image"
            />
            <p>{testimonial.text}</p>
            <i className="bx bxs-quote-alt-left quote-icon"></i>
            <div className="details">
              <span className="name">{testimonial.name}</span>
              <span className="job">{testimonial.job}</span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default Testimonial;
