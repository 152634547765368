import React from 'react';
import './About.css';
import { assets } from '../../assets/assets';

const About = () => {
  return (

    <div className='about'>

      <img src={assets.IMG_K} alt="" className='img-me'/>

      <div className='info'>
        <h1> ABOUT&nbsp; <span className='me'>ME</span></h1>
        <p>I'm Khalid Ali Farah, a passionate and dedicated software developer specializing in mobile app and web development. I have worked on numerous projects that showcase my skills in creating intuitive and user-friendly websites and applications.

I enjoy every step of the development process, from brainstorming ideas to delivering polished solutions. Driven by a love for learning and innovation, I take pride in building projects that make a difference. Let’s collaborate and bring your ideas to life!</p>
<a href={assets.cv} className="btn" download>
<i className="fas fa-download"></i>  Download CV
    </a>
      </div>

    </div>
  
     
  );
};

export default About;
