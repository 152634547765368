import React, { useEffect, useState } from 'react';
import './Greeting.css'; // Importing the CSS file

const Greeting = () => {
  const [greetingIndex, setGreetingIndex] = useState(0);
  const greetings = ["مرحباً", "Hello", "你好", "Bonjour", "こんにちは", "Hola"];
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // Show each greeting one by one with a faster delay
    const timer = setInterval(() => {
      setGreetingIndex((prevIndex) => {
        if (prevIndex < greetings.length - 1) {
          return prevIndex + 1;
        } else {
          // Hide the greeting after showing the last one
          clearInterval(timer);
          setVisible(false);
          return prevIndex;
        }
      });
    }, 300); // Reduced delay to 1 second

    // Cleanup the timer on component unmount
    return () => clearInterval(timer);
  }, []);

  return (
    visible && (
      <div className="greeting-container">
        <div className="line"></div>
        <p className="greeting-text">{greetings[greetingIndex]}</p>
      </div>
    )
  );
};

export default Greeting;
