//pdf cv file
import cv from './cv.pdf'

import my1 from './myGallery/my1.jpg'
import my2 from './myGallery/my2.jpeg'
import my3 from './myGallery/my3.jpeg'
import my4 from './myGallery/my4.jpeg'
import my5 from './myGallery/my5.jpeg'
import my6 from './myGallery/my6.jpeg'
import my7 from './myGallery/my7.jpeg'
import my8 from './myGallery/my8.jpeg'
import my9 from './myGallery/my9.jpeg'

import IMG_K from './IMG_K.png'
import hero_img from './hero_img.png'
import boss from './boss.png'
import logo from './logo.png'
import p from './p.jpg'
import pp from './pp.png'
import wise from './wise.png'
import eastStarMarine from './eastStarMarine.png'
import khalid from './khalid.png'
import wave from './wave.png'
import convo from './convo.jpg'
import webDev from './webDev.jpg'
import uiUx from './uiUx.jpg'
import flutter from './flutter.jpg'
import adali from './adali.jpeg'
import yung from './yung.jpeg'
import andoli from './andoli.jpeg'
import eng from './eng.jpeg'
import meeting from './meeting.jpeg'
import wisee from './wisee.png'









export const assets = {
  IMG_K,
  hero_img,
  logo,
  p,
  pp,
  boss,
  wise,
  eastStarMarine,
  khalid,
  wave,
  convo,
  webDev,
  uiUx,
  flutter,
  adali,
  yung,
  andoli,
  eng,
  meeting,
  wisee,
  my1,
  my2,
  my3,
  my4,
  my5,
  my6,
  my7,
  my8,
  my9,
  cv,
    

}





export const projects = [
    {
      title: "Wise Vision Engineering",
      description: "Developed a professional website for Wise Vision Engineering Company, showcasing their 23+ years of expertise in sourcing, supply chain solutions, and military logistics.",
      image: [wise], // Replace with actual image
      link: "https://www.wise-v-e.com/", // Replace with actual project link
    },
    {
      title: "East Star Marine",
      description: "Designed a comprehensive website for East Star Marine, highlighting their expertise in shipbuilding, fleet management, and sustainable maritime solutions.",
      image: [eastStarMarine],
      link: "https://eaststarmarine.com", // Replace with actual project link
    },
    {
      title: "Personal Portfolio Website",
      description: "Designed and developed a personal portfolio website to showcase my skills, projects, and professional achievements with a clean and responsive UI.",
      image: [khalid],
      link: "https://www.khalidalifarah.com", // Replace with actual project link
    },
    // Add more projects as needed

 
  ];