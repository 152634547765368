import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { assets } from '../../assets/assets';

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  // Add scroll event listener to change background color
  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.scrollY > 0 ? setIsScrolled(true) : setIsScrolled(false);
    });
  }, []);

  window.addEventListener('scroll', () => {
    const navbar = document.querySelector('.nav-container');
    if (window.scrollY > 0) {
      navbar.classList.add('nav-dark');
      navbar.classList.add('text-scroll');
    } else {
      navbar.classList.remove('nav-dark');
      navbar.classList.remove('text-scroll');
    }
  });

  const [mobileMenu, setMobileMenu] = useState(false);

  const toggleMenu = () => {
    setMobileMenu(!mobileMenu); // Toggle the menu
  };

  return (
    <div className={`nav-container ${isScrolled ? 'nav-dark' : ''}`}>
      <a href="/" title="Go to Home" aria-label="Navigate to Home">
        <img
          src={assets.logo}
          alt="Company Logo"
          className={`logo ${isScrolled ? 'logo-scrolled' : ''}`}
        />
      </a>
      <ul className={`menu-list ${isScrolled ? 'text-scrolled' : ''} ${mobileMenu ? 'show-menu' : 'menu-hidden'}`}>
        <li>
          <a href="#hero" className="nav-link">Home</a>
        </li>
        <li>
          <a href="#about" className="nav-link">About</a>
        </li>
        <li>
          <a href="#work" className="nav-link">Work</a>
        </li>
        <li>
          <a href="#contact" className="nav-link">Contact</a>
        </li>
      </ul>
      <div className="right-section">
        <ul className={`social-icons ${isScrolled ? 'social-scrolled' : ''}`}>
          <li>
            <a
              href="https://www.linkedin.com/in/khalid-ali-farah/"
              target="_blank"
              rel="noopener noreferrer"
            >
             <i className="fab fa-linkedin" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Khalid-Ali-Farah"
              target="_blank"
              rel="noopener noreferrer"
            >
             <i className="fab fa-github" aria-hidden="true"></i>
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/khalid.ali.farah/"
              target="_blank"
              rel="noopener noreferrer"
            >
             <i className="fab fa-instagram" aria-hidden="true"></i>
            </a>
          </li>
        </ul>
        <i
          className={`fa ${mobileMenu ? 'fa-times' : 'fa-bars'}`}
          onClick={toggleMenu}
        ></i>
      </div>
    </div>
  );
};

export default Navbar;
