import React from 'react';
import './BentoGridLayout.css';
import Title from '../Title/Title';
import { assets } from '../../assets/assets';

const BentoGridLayout = () => {
  return (
    <div className="unique-bg-container">
      <div className="unique-container">
        <Title subtitle='My ' title='gallery photos'/>
        
        <div className="unique-grid">
          {/* Large item */}
          <div className="unique-large-item">
            <img
              src={assets.my1}
              alt="Nature"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h3 className="unique-title">Graduation Achievement</h3>
                <p className="unique-description">A proud moment as I receive my graduation certificate, marking the culmination of hard work and dedication.</p>
              </div>
            </div>
          </div>

          {/* Two small items */}
          <div className="unique-small-item">
            <img
              src={assets.my2}
              alt="Food"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Meeting Discussion</h4>
              </div>
            </div>
          </div>
          <div className="unique-small-item">
            <img
              src={assets.my4}
              alt="Technology"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Meeting Discussion</h4>
              </div>
            </div>
          </div>

          {/* Three medium items */}
          <div className="unique-medium-item">
            <img
              src={assets.my5}
              alt="Travel"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Grateful Moment</h4>
              </div>
            </div>
          </div>
          <div className="unique-medium-item">
            <img
              src={assets.my8}
              alt="Art"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Last Day of Internship</h4>
              </div>
            </div>
          </div>

          {/* Bottom cards */}
          <div className="unique-bottom-item">
            <img
              src={assets.my3}
              alt="Sport"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Cherished Moments</h4>
              </div>
            </div>
          </div>
          <div className="unique-bottom-item">
            <img
              src={assets.my7}
              alt="Sport"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Workplace Memories</h4>
              </div>
            </div>
          </div>
          <div className="unique-bottom-item">
            <img
              src={assets.my6}
              alt="Sport"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Workplace Memories</h4>
              </div>
            </div>
          </div>
          <div className="unique-bottom-item">
            <img
              src={assets.my9}
              alt="Sport"
              className="unique-img"
            />
            <div className="unique-overlay">
              <div className="unique-overlay-content">
                <h4 className="unique-title">Connections</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BentoGridLayout;
