import React, { useState, useEffect } from 'react';
import './Hero.css';
import { assets } from '../../assets/assets';

const Hero = () => {
  const roles = ['Software Developer'];
  const [text, setText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const [roleIndex, setRoleIndex] = useState(0);
  const [charIndex, setCharIndex] = useState(0);
  const [speed, setSpeed] = useState(50); // Typing/deleting speed

  useEffect(() => {
    const handleTyping = () => {
      const currentRole = roles[roleIndex];
      if (!isDeleting) {
        // Typing characters
        setText(currentRole.substring(0, charIndex + 1));
        setCharIndex((prev) => prev + 1);

        // Check if full word is typed
        if (charIndex === currentRole.length) {
          setIsDeleting(true);
          setSpeed(200); // Pause before deleting
        }
      } else {
        // Deleting characters
        setText(currentRole.substring(0, charIndex - 1));
        setCharIndex((prev) => prev - 1);

        // Check if word is fully deleted
        if (charIndex === 0) {
          setIsDeleting(false);
          setSpeed(50); // Reset speed for typing
          setRoleIndex((prev) => (prev + 1) % roles.length); // Move to next role
        }
      }
    };

    const timer = setTimeout(handleTyping, speed);
    return () => clearTimeout(timer);
  }, [text, isDeleting, charIndex, roleIndex, speed, roles]);

  return (
    <div className='Hero hero-section animate'>
      <div className='hero-content animate'>
        <h1 className='hero-title-t animate' >Hi, It's Khalid Ali</h1>
        <h2 className='hero-subtitle-t animate'>
          I'm a <span className="typing ">{text}|</span>
        </h2>
        <p className='hero-description animate'>A passionate developer who loves creating intuitive and engaging user experiences.</p>
       
      </div>
      <img src={assets.hero_img} alt="Featured" className='hero-image animate' />
    </div>
  );
};

export default Hero;
