import React from 'react';
import './Talk.css';

const Talk = () => {
  return (
    <div className="talk-section">
      <div className="talk-content">
        <div className="content-container">
          <div className="talk-inner">
            <div className="text-content">
              <h3><span>I'm available for freelance work</span></h3>
            </div>
            <div className="contact-button">
              <a href="#contact">Contact Me</a>
            </div>
          </div>
        </div>  
      </div>
    </div>
  );
};

export default Talk;
