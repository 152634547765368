import React from 'react';
import './Resume.css';
import { assets } from '../../assets/assets';


const Resume = () => {
  return (
    <div>
      <main className="row">
        {/* Education Section */}
        <section className="col">
          <header className="title">
            <h2>EDUCATION</h2>
          </header>
          <div className="contents">

          <div className="box">
  <h4>2021 - 2024</h4>
  <h3>Bachelor of Computer Science (Software Development) with Honours</h3>
  <p><a href="https://utem.edu.my/en/" target="_blank">Universiti Teknikal Malaysia Melaka</a></p>
  <img src={assets.convo} alt="Bachelor's Degree" className="popup-image" />
</div>
<div className="box">
  <h4>2024</h4>
  <h3>Full-Stack Developer</h3>
  <p>Completed "The Complete 2024 Web Development Bootcamp" on Udemy.</p>
  <img src={assets.webDev} alt="Full-Stack Development" className="popup-image" />
</div>

<div className="box">
  <h4>2024</h4>
  <h3>Flutter Developer</h3>
  <p>Completed "The Complete Flutter Development Bootcamp with Dart" on Udemy.</p>
  <img src={assets.flutter} alt="Flutter Development" className="popup-image" />
</div>
<div className="box">
  <h4>2024</h4>
  <h3>UI/UX Designer</h3>
  <p>Completed "Complete Web & Mobile Designer: UI/UX, Figma, +more" on Udemy.</p>
  <img src={assets.uiUx} alt="UI/UX Design" className="popup-image" />
</div>




            <div className="box">
  <h4>2019</h4>
  <h3>General English Course</h3>
  <p>Successfully completed a comprehensive General English course at <a href="https://www.elit.edu.my/">ELIT Language Centre</a>.</p>
  <img src={assets.eng} alt="General English Course" className="popup-image" />
</div>
            <div className="box">
              <h4>2016 - 2017</h4>
              <h3>High School Degree</h3>
              <p>Completed my high school education with a focus on academic development and extracurricular activities.</p>
              <img src={assets.IMG_K} alt="High School" className="popup-image" />
            </div>

        

       
          </div>
        </section>

        {/* Experience Section */}
        <section className="col">
          <header className="title">
            <h2>EXPERIENCE</h2>
          </header>
          <div className="contents">

          <div className="box">
  <h4>2024 - Present</h4>
  <h3>Company Portfolio Developer for Wise Vision Engineering</h3>
  <p>
    Created a comprehensive company portfolio for Wise Vision Engineering, focusing on 
    delivering a clean, professional, and visually appealing website. The portfolio 
    effectively showcases their services, projects, and expertise, enhancing their online 
    presence and helping them engage potential clients.
  </p>
  <img src={assets.wisee} alt="Wise Vision Engineering Portfolio" className="popup-image" />
</div>
  
<div className="box">
  <h4>Mar 2024 - Aug 2024</h4>
  <h3>Flutter Developer (Internship)</h3>
  <p>Intern at Pusat Sumber & Teknologi Pengajaran (PSTP), UTeM. Gaining hands-on experience in developing mobile applications using Flutter and Dart.</p>
  <img src={assets.meeting} alt="Flutter Development" className="popup-image" />
</div>

<div className="box">
  <h4>Mar 2024 - Aug 2024</h4>
  <h3>Asset Borrowing App Developer</h3>
  <p>
    Developed the Asset Borrowing App using Flutter to streamline the process of borrowing 
    and returning items. Integrated QR code scanning and database validation features, 
    ensuring efficient management and tracking of assets. The app helps users check item 
    availability and complete borrowing processes seamlessly.
  </p>
  <img src={assets.flutter} alt="Asset Borrowing App" className="popup-image" />
</div>

<div className="box">
  <h4>2024 - Present</h4>
  <h3>Freelance Developer</h3>
  <p>Working independently on various projects for clients, providing development, design, and consultation services. Specializing in building custom solutions and delivering high-quality, scalable applications to meet client needs.</p>
  <img src={assets.IMG_K} alt="Freelance Development" className="popup-image" />
</div>


          </div>
        </section>
      </main>
    </div>
  );
};

export default Resume;
