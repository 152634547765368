import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="wave"></div>
      <div className="footer-content">
        <div className="footer-logo">
          <h2>Khalid Ali</h2>
        </div>
        <div className="footer-social">
          <a href="#" aria-label="Facebook">
            <i className="fab fa-linkedin"></i>
          </a>
          <a href="#" aria-label="GitHub">
            <i className="fab fa-github"></i>
          </a>
          <a href="#" aria-label="Instagram">
            <i className="fab fa-instagram"></i>
          </a>
        </div>
        <div className="footer-copy">
          <p>&copy; www.khalidalifarah.com. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
