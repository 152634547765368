import React from 'react';
import About from './components/About/About.jsx';
import Hero from './components/Hero/Hero.jsx';
import Work from './components/Work/Work.jsx';
import Contact from './components/Contact/Contact.jsx';
import Footer from './components/Footer/Footer.jsx';
import Navbar from './components/Navbar/Navbar.jsx';
import Skills from './components/Skills/Skills.jsx';
import Testimonial from './components/Testimonial/Testimonial.jsx';

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Talk from './components/Talk/Talk.jsx';
import Title from './components/Title/Title.jsx';
import Resume from './components/Resume/Resume.jsx';
import Greeting from './components/Greeting/Greeting.jsx';
import BentoGridLayout from './components/BentoGridLayout/BentoGridLayout.jsx';






const App = () => {
  return (
    <div>
      <Greeting />
      <Navbar />
      <div id="hero">
        <Hero />
      </div>

      <div id="about">
        <About />
      </div>

      <Title subtitle='My ' title='Resume'/>

      <Resume />
  
      <Skills />
      
      <BentoGridLayout />
     
  <div id="work">
        <Work />
      </div>
     
    <div id='Talk'>
    <Talk />
    </div>
    
      <div id="Testimonial"> <Testimonial /></div>

 
      
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </div>
  );
}

export default App;
