import React, { useState } from 'react';
import './Contact.css'; // Import CSS for styling

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const [result, setResult] = useState("");

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setResult("Sending...");
  
    const formDataToSubmit = new FormData(event.target);
    formDataToSubmit.append("access_key", "aac42f11-844e-4ef4-aabe-8de415817e96"); // Replace with your Web3Forms API key
  
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataToSubmit,
      });
  
      console.log("Request Sent:", formDataToSubmit);
  
      const data = await response.json();
  
      console.log("Response Received:", data);
  
      if (data.success) {
        setResult("Form Submitted Successfully!");
        event.target.reset(); // Reset form after successful submission
      } else {
        console.error("Error Response:", data);
        setResult(data.message || "Failed to send the message.");
      }
    } catch (error) {
      console.error("Network Error:", error);
      setResult("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="contact-container">
      <div className="contact-left">
        <h1>Get in touch</h1>
        <p>
        Please fill out the form on this section to contact with me. Or call between 9:00 a.m. and 8:00 p.m. ET, Monday through Friday.
        </p>
        <p><strong><i class="fas fa-map-marker-alt"></i> Address:</strong> Kuala Lumpur, Malaysia</p>
<p><strong><i class="fas fa-envelope"></i>Email:</strong>  <a href="mailto:khalid.ali.farah.15@gmail.com">khalid.ali.farah.15@gmail.com</a></p>
<p><strong><i class="fas fa-phone "></i> Phone:</strong>  <a href="tel:+60137409285">+60 137409285</a></p>
<p><strong><i class="fas fa-laptop"></i> Website:</strong>  <a href="https://www.khalidalifarah.com" target="_blank">www.khalidalifarah.com</a></p>
<p><strong><i class="fab fa-linkedin"></i> Linkedin:</strong>  <a href="https://www.linkedin.com/in/khalid-ali-farah-8846a3294/" target="_blank">Khalid Ali Farah</a></p>
<p><strong><i class="fab fa-instagram"></i> Instagram:</strong>  <a href="https://www.instagram.com/khalid_1ali" target="_blank">@khalid_1ali</a></p>

      </div>
  
      <div className="contact-right">
        <div className="contact-content">
         
  
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="name">Your Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
  
            <div className="form-group">
              <label htmlFor="email">Your Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
  
            <div className="form-group">
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter subject"
                required
              />
            </div>
  
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Write your message"
                required
              ></textarea>
            </div>
  
            <button type="submit">SEND MESSAGE</button>
          </form>
  
          {result && <p className="result-message">{result}</p>}
        </div>
      </div>
    </div>
  );
};

export default Contact;
