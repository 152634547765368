import React from 'react'
import './Skills.css'

const Skills = () => {
  return (
    <div className="skills-section">
      <div className="text-section">
        <h2>I have high skills in developing and programming</h2>
        <p>
        I am dedicated to delivering cost-effective, high-quality products that satisfy my customers. With a focus on software development, mobile app creation, and UI/UX design, I strive to provide solutions that exceed expectations.
        </p>
      </div>
      <div className="sliders-section">
        <div className="skill">
          <label>Web Development</label>
          <div className="slider-container">
            <div className="slider">
              <div className="progress" style={{ width: "95%" }}></div>
            </div>
            <span className="percentage">95%</span>
          </div>
        </div>
        <div className="skill">
          <label>Mobile Development</label>
          <div className="slider-container">
            <div className="slider">
              <div className="progress" style={{ width: "87%" }}></div>
            </div>
            <span className="percentage">87%</span>
          </div>
        </div>
        <div className="skill">
          <label>UI/UX</label>
          <div className="slider-container">
            <div className="slider">
              <div className="progress" style={{ width: "70%" }}></div>
            </div>
            <span className="percentage">70%</span>
          </div>
        </div>
      </div>
      
    </div>
  );

}

export default Skills