import React from 'react';
import './Title.css';

const Title = ({ subtitle = "Default Subtitle", title = "Default Title" }) => {
  return (
    <div className="title">
      {subtitle && <p>{subtitle}</p>}  {/* Render subtitle only if it's provided */}
      <h2>{title}</h2>
    </div>
  );
};

export default Title;
